import * as React from "react"
import styled from 'styled-components';
import LogoImg from '../images/logo.png';

const FooterContainer = styled.div`
clear: both;
height: auto;
width: 100%;
font-family: Poppins, sans-serif;
}
`;

const FooterSection = styled.section`
background: rgb(89, 129, 144);
    padding: 50px;

    @media screen and (max-width: 1000px) {
  padding: 30px;
}
`;

const InfoSection = styled.div`
width: 100%;
    margin: 0px auto;
    display: inline-block;

    @media screen and (max-width: 1000px) {
      display: block;
    }
`;

const CopyrightContainer = styled.div`
font-size: 12px;
color: white;
text-align: center;
background: rgb(224, 224, 224);
padding: 5px;
`;

const Logo = styled.img`
display: inherit;
    max-width: 100%;

    @media screen and (max-width: 1000px) {
      margin: 0px auto;
      display: block;
      max-width: 200px;
    }
`;

const InfoColumn = styled.div`
display: inline-block;
width: 20%;
padding: 10px;
color: white;
vertical-align: top;
text-align: center;

@media screen and (max-width: 1000px) {
  width: 100%;
  display: block;
}

p{
  margin: 0px;
  margin-bottom: 8px;
  font-size: 14px;
}
`;

const Title = styled.h4`
font-size: 20px;
margin-bottom: 8px;
margin-top: 0px;
}
`;

const Footer = () => {
  return (
    <FooterContainer>
        <FooterSection>
        <InfoSection>
          <Logo src={LogoImg} alt="Dunnin Currie Insurance Logo" />
          <InfoColumn>
            <Title>Address</Title>
            <p>3080 Yonge Street,</p>
            <p>Suite 6060,</p>
            <p>Toronto, Ontario,</p>
            <p>M4N 3N1</p>
             </InfoColumn>
             <InfoColumn>
            <Title>Hours Of Opperation</Title>
            <p>Monday: 8:30-5:00</p>
            <p>Tuesday: 8:30-5:00</p>
            <p>Wednesday: 8:30-5:00</p>
            <p>Thursday: 8:30-5:00</p>
            <p>Friday: 8:30-5:00</p>
             </InfoColumn>
             <InfoColumn>
            <Title>Contact Info</Title>
            <p>Email: info@dcii.ca</p>
            <p>Phone: 647-250-8929</p>
            <p>Phone: 1-877-350-3627 (toll free)</p>
             </InfoColumn>
        </InfoSection>
        </FooterSection>
        <CopyrightContainer>
            <p>&copy; 2023</p>
        </CopyrightContainer>
    </FooterContainer>
  )
}

export default Footer;